




















































































































import Vue from 'vue';
import ActionButtons from '@/views/components/navigation/action-buttons.vue';
import InputNoteWrapperElement from '@/views/tools/notes/input-note-wrapper-element.vue';
import store from '@/scripts/store';
import { ISleepTracker } from '@/scripts/store/modules/tools/sleep/types';
import { DATE_FORMAT } from '@/views/components/sm-input-date-field.vue';
import { FORMAT_HHMM } from '@/views/components/sm-input-time-field.vue';
import { INote } from '@/scripts/store/modules/tools/notes/types';
import { format, parse } from 'date-fns';

export default Vue.extend({
  name: 'sleep-track-form-score',
  components: { ActionButtons, InputNoteWrapperElement },
  props: {
    value: {
      type: Object as () => ISleepTracker,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      internalValue: this.value,
      note: {
        id: undefined,
        note: '',
        date: new Date(),
        type: 'SLEEP_TRACK',
        uen: undefined,
      } as INote,
    };
  },
  computed: {
    totalAsleep(): Object | undefined {
      const minutesAsleep = this.internalValue.minutesAsleep || 0;
      const hours = Math.floor(minutesAsleep / 60);
      const minutes = minutesAsleep % 60;
      return { hours, minutes };
    },
    totalScoreStyle(): string | undefined {
      if (!this.internalValue.efficiencyScore || this.internalValue.efficiencyScore < 15) return;
      if (this.internalValue.efficiencyScore > 85) {
        return 'width: 100%; text-align: right';
      }
      return `width: 200px; text-align: center; margin-left: calc(${this.internalValue.efficiencyScore}% - 100px)`;
    },
    currentLocale() {
      return store.getters.getCurrentLocale;
    },
    abbreviatedDateFormat() {
      return store.getters.getAbbreviatedDateFormat;
    },
    fullDateFormat(): string {
      return `${this.currentLocale.formatLong?.time({ width: 'short' })}, ${this.abbreviatedDateFormat}`;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue === oldValue) return;
        this.internalValue = newValue;
        this.fetchNote();
      },
    },
  },
  methods: {
    formatDate(internalValue?: string) {
      if (!internalValue) return;
      const date = parse(internalValue, DATE_FORMAT, new Date());
      return format(date, this.abbreviatedDateFormat, { locale: this.currentLocale });
    },
    formatTime(internalValue?: string) {
      if (!internalValue) return;
      const time = parse(internalValue, FORMAT_HHMM, new Date());
      return format(time, this.currentLocale.formatLong?.time({ width: 'short' }), { locale: this.currentLocale });
    },
    onEdit() {
      this.$emit('edit');
    },
    onSave() {
      this.$emit('save');
    },
    fetchNote() {
      if (!this.internalValue.noteId) return;
      this.loading = true;
      try {
        this.$store.dispatch('notes/getNote', this.internalValue.noteId).then(res => {
          if (!res || res.status >= 300) throw new Error();
          this.note = { ...res };
        });
      } catch (error) {
        this.$log.debug(error);
      } finally {
        this.loading = false;
      }
    },
    onNoteUpdated(updatedNote: INote) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const state = {
        ...updatedNote,
        uen: this.internalValue.uen,
      };
      try {
        this.loading = true;
        this.$store.dispatch('notes/saveNote', state).then(res => {
          this.note = res;

          if (!this.internalValue.noteId || res.id !== this.internalValue.noteId) {
            this.internalValue.noteId = res.id;
            this.$emit('update', this.internalValue);
          }
        });
      } catch (error) {
        this.$log.debug(error);
      } finally {
        this.$nextTick(() => {
          this.loading = false;
        });
      }
    },
  },
});
