






























































































import Vue from 'vue';
import ActionButtons from '@/views/components/navigation/action-buttons.vue';
import SmInputDateField from '@/views/components/sm-input-date-field.vue';
import SmInputTimeField, { FORMAT_HHMM } from '@/views/components/sm-input-time-field.vue';
import store from '@/scripts/store';
import moment from 'moment';
import { ISleepTracker } from '@/scripts/store/modules/tools/sleep/types';
import { addDays, differenceInDays, isAfter, parse } from 'date-fns';

export default Vue.extend({
  name: 'sleep-track-form-calculate',
  components: { ActionButtons, SmInputDateField, SmInputTimeField },
  props: {
    value: {
      type: Object as () => ISleepTracker,
      required: true,
    },
    cancellable: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      internalValue: {} as ISleepTracker,
      valid: true,
      validTimeAsleep: true,
      validTimeWokeUp: true,
      validTimeOutBed: true,
    };
  },
  computed: {
    maxDate() {
      return moment().subtract(1, 'day').format('YYYY-MM-DD');
    },
    currentLanguage() {
      return store.getters.currentLanguage;
    },
    currentLocale() {
      return store.getters.getCurrentLocale;
    },
    abbreviatedDateFormat() {
      return store.getters.getAbbreviatedDateFormat;
    },
    allFieldsFilled(): boolean {
      return !!this.internalValue?.refDate && !!this.internalValue?.timeInBed && !!this.internalValue?.timeAsleep && !!this.internalValue?.timeWokeUp && !!this.internalValue?.timeOutBed;
    },
    validTimes(): boolean {
      return !!this.validTimeAsleep && !!this.validTimeWokeUp && !!this.validTimeOutBed;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue === oldValue) return;
        this.setSleep(newValue);
      },
    },
  },
  methods: {
    setSleep(sleep?: ISleepTracker) {
      if (!sleep) {
        this.internalValue = {};
        return;
      }
      this.internalValue = sleep;

      if (!sleep.refDate) {
        this.internalValue.refDate = this.maxDate;
      }

      if (this.$refs && this.$refs.form) {
        const formElement: HTMLFormElement = this.$refs.form as HTMLFormElement;
        formElement.resetValidation();
      }
    },
    onSave() {
      const formElement: HTMLFormElement = this.$refs.form as HTMLFormElement;
      if (!formElement.validate() || !this.validate()) return;
      this.$emit('save', this.internalValue);
    },
    onCancel() {
      this.setSleep(this.value);
      this.$emit('cancel');
    },
    validate() {
      this.valid = true;
      this.validTimeAsleep = true;
      this.validTimeWokeUp = true;
      this.validTimeOutBed = true;

      if (!this.internalValue?.timeInBed || !this.internalValue?.timeAsleep || !this.internalValue?.timeWokeUp || !this.internalValue?.timeOutBed) {
        this.valid = false;
        return false;
      }

      const timeInBed = parse(this.internalValue.timeInBed, FORMAT_HHMM, new Date());
      let timeAsleep = parse(this.internalValue.timeAsleep, FORMAT_HHMM, new Date());
      let timeWokeUp = parse(this.internalValue.timeWokeUp, FORMAT_HHMM, new Date());
      let timeOutBed = parse(this.internalValue.timeOutBed, FORMAT_HHMM, new Date());

      if (timeInBed.getHours() >= 12) {
        if (timeAsleep.getHours() < 12) {
          timeAsleep = addDays(timeAsleep, 1);
          timeWokeUp = addDays(timeWokeUp, 1);
          timeOutBed = addDays(timeOutBed, 1);
        } else if (timeWokeUp.getHours() < 12) {
          timeWokeUp = addDays(timeWokeUp, 1);
          timeOutBed = addDays(timeOutBed, 1);
        } else if (timeOutBed.getHours() < 12) {
          timeOutBed = addDays(timeOutBed, 1);
        }
      } else if (timeAsleep.getHours() >= 12) {
        if (timeWokeUp.getHours() < 12) {
          timeWokeUp = addDays(timeWokeUp, 1);
          timeOutBed = addDays(timeOutBed, 1);
        } else if (timeOutBed.getHours() < 12) {
          timeOutBed = addDays(timeOutBed, 1);
        }
      } else if (timeWokeUp.getHours() >= 12) {
        if (timeOutBed.getHours() < 12) {
          timeOutBed = addDays(timeOutBed, 1);
        }
      }

      if (isAfter(timeInBed, timeAsleep)) {
        this.valid = false;
        this.validTimeAsleep = false;
      }
      if (isAfter(timeInBed, timeWokeUp)) {
        this.valid = false;
        this.validTimeWokeUp = false;
      }
      if (isAfter(timeInBed, timeOutBed)) {
        this.valid = false;
        this.validTimeOutBed = false;
      }
      if (isAfter(timeAsleep, timeWokeUp)) {
        this.valid = false;
        this.validTimeWokeUp = false;
      }
      if (isAfter(timeAsleep, timeOutBed)) {
        this.valid = false;
        this.validTimeOutBed = false;
      }
      if (isAfter(timeWokeUp, timeOutBed)) {
        this.valid = false;
        this.validTimeOutBed = false;
      }
      if (differenceInDays(timeOutBed, timeInBed) > 0) {
        this.valid = false;
        this.validTimeOutBed = false;
      }

      return this.valid;
    },
  },
});
