var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "input-wrapper-note px-2 my-2",
      class: { readonly: _vm.readonly },
      attrs: { flat: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "title-wrapper ma-0 pa-0", attrs: { cols: "12" } },
            [
              _c("p", { staticClass: "starling-small-text" }, [
                _vm.title
                  ? _c(
                      "strong",
                      { style: "color:" + _vm.color + ";font-size:1.2em;" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$te(_vm.title) ? _vm.$t(_vm.title) : _vm.title
                          )
                        )
                      ]
                    )
                  : _vm._e(),
                _c("strong", [_vm._v(" - " + _vm._s(_vm.noteTitle))])
              ])
            ]
          )
        ],
        1
      ),
      _vm.isNativePlatform
        ? _c(
            "div",
            [
              _c("input-note-native", {
                attrs: { loading: _vm.loading, note: _vm.safeNote.note },
                on: {
                  updated: _vm.onUpdated,
                  recording: _vm.onRecordingStateChanged
                }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c("input-note-web", {
                attrs: { loading: _vm.loading, note: _vm.safeNote.note },
                on: {
                  updated: _vm.onUpdated,
                  recording: _vm.onRecordingStateChanged
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }