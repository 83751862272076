<template>
  <svg xmlns="http://www.w3.org/2000/svg" :viewBox="showLabels?'0 0 410.79 320.26':'55 0 280 280'" ref="graph" :width="canvasWidth" class="d-block" style="margin-left: auto;margin-right: auto;">
    <g class="ENERGY" v-if="ENERGY">
      <text v-if="showLabels" class="label" @click="onSelect('ENERGY')" :fill="ENERGY.color" transform="translate(278.95 15.42)">{{ ENERGY.assessmentLabel }}</text>
      <text v-if="showLabels" class="value" transform="translate(278.31 37.52)">{{ ENERGY.label }}</text>

      <path
          class="very-low"
          @click="onSelect('ENERGY')"
          :fill="ENERGY.value >= 0?  ENERGY.color : '#ededed'"
          d="M286.86,242.27a27.74,27.74,0,0,0-12.61-9.21,8.41,8.41,0,0,1-5.47-7.94h0a8.49,8.49,0,0,1,11.35-8,44.69,44.69,0,0,1,20.15,14.72,8.5,8.5,0,0,1-4.14,13.24h0A8.42,8.42,0,0,1,286.86,242.27Z"
          transform="translate(-66.11 -121.87)"/>
      <path
          class="low"
          @click="onSelect('ENERGY')"
          :fill="ENERGY.value >= 1?  ENERGY.color : '#ededed'"
          d="M310.72,233.13a52.66,52.66,0,0,0-35.11-25.6,8.54,8.54,0,0,1-6.83-8.33h0a8.5,8.5,0,0,1,10.2-8.33,69.58,69.58,0,0,1,46.56,34,8.5,8.5,0,0,1-4.78,12.26h0A8.53,8.53,0,0,1,310.72,233.13Z"
          transform="translate(-66.11 -121.87)"/>

      <path
          class="moderate"
          @click="onSelect('ENERGY')"
          :fill="ENERGY.value >= 2?  ENERGY.color : '#ededed'"
          d="M334.66,225A77.57,77.57,0,0,0,276,182.22a8.46,8.46,0,0,1-7.2-8.4h0a8.52,8.52,0,0,1,9.8-8.41,94.55,94.55,0,0,1,71.28,51.92,8.52,8.52,0,0,1-5,11.9h0A8.46,8.46,0,0,1,334.66,225Z"
          transform="translate(-66.11 -121.87)"/>
      <path
          class="moderate"
          @click="onSelect('ENERGY')"
          :fill="ENERGY.value >= 3?  ENERGY.color : '#ededed'"
          d="M358.45,216.78a102.54,102.54,0,0,0-82-59.71,8.54,8.54,0,0,1-7.64-8.45h0a8.5,8.5,0,0,1,9.42-8.45A119.39,119.39,0,0,1,374,209.88a8.5,8.5,0,0,1-5.13,11.56h0A8.53,8.53,0,0,1,358.45,216.78Z"
          transform="translate(-66.11 -121.87)"/>
    </g>

    <g class="PHQ9" v-if="PHQ9">
      <text v-if="showLabels" class="label" @click="onSelect('PHQ9')" :fill="PHQ9.color" transform="translate(175.55 288.2)">{{ PHQ9.assessmentLabel }}</text>
      <text v-if="showLabels" class="value" transform="translate(175.06 311.43)">{{ PHQ9.label }}</text>

      <path
          class="very-low"
          @click="onSelect('PHQ9')"
          :fill="PHQ9.value >= 0?  PHQ9.color : '#ededed'"
          d="M256.47,284.67a27.76,27.76,0,0,0,15.62,0,8.41,8.41,0,0,1,9.09,3.2h0a8.48,8.48,0,0,1-4.47,13.15,44.58,44.58,0,0,1-24.95-.07,8.49,8.49,0,0,1-4.44-13.14h0A8.41,8.41,0,0,1,256.47,284.67Z"
          transform="translate(-66.11 -121.87)"/>
      <path
          class="low"
          @click="onSelect('PHQ9')"
          :fill="PHQ9.value >= 1?  PHQ9.color : '#ededed'"
          d="M242.55,306.09a52.65,52.65,0,0,0,43.45.08,8.51,8.51,0,0,1,10.42,2.72h0a8.5,8.5,0,0,1-3.35,12.74,69.57,69.57,0,0,1-57.63-.1,8.5,8.5,0,0,1-3.34-12.73h0A8.52,8.52,0,0,1,242.55,306.09Z"
          transform="translate(-66.11 -121.87)"/>
      <path
          class="moderate"
          @click="onSelect('PHQ9')"
          :fill="PHQ9.value >= 2?  PHQ9.color : '#ededed'"
          d="M264.78,354.12a93.9,93.9,0,0,1-44-10.83,8.44,8.44,0,0,1-2.94-12.43l.1-.14a8.37,8.37,0,0,1,10.67-2.52,77.71,77.71,0,0,0,72.3,0,8.37,8.37,0,0,1,10.67,2.52l.1.14a8.44,8.44,0,0,1-2.94,12.43A93.9,93.9,0,0,1,264.78,354.12Z"
          transform="translate(-66.11 -121.87)"/>
      <path
          class="high"
          @click="onSelect('PHQ9')"
          :fill="PHQ9.value >= 3?  PHQ9.color : '#ededed'"
          d="M264.78,379.12a119,119,0,0,1-59-15.53A8.45,8.45,0,0,1,203,351.28l.12-.16a8.46,8.46,0,0,1,11.06-2.32,102.69,102.69,0,0,0,101.16,0,8.46,8.46,0,0,1,11.06,2.32l.12.16a8.45,8.45,0,0,1-2.71,12.31A119,119,0,0,1,264.78,379.12Z"
          transform="translate(-66.11 -121.87)"/>
    </g>

    <g class="GAD7" v-if="GAD7">
      <text v-if="showLabels" class="label" @click="onSelect('GAD7')" :fill="GAD7.color" transform="translate(321.68 191.82)">{{ GAD7.assessmentLabel }}</text>
      <text v-if="showLabels" class="value" transform="translate(321.68 213.08)">{{ GAD7.label }}</text>

      <path
          class="very-low"
          @click="onSelect('GAD7')"
          :fill="GAD7.value >= 0?  GAD7.color : '#ededed'"
          d="M287.07,274.51a27.68,27.68,0,0,0,4.86-14.84,8.42,8.42,0,0,1,5.86-7.66h0a8.49,8.49,0,0,1,11.12,8.32A44.53,44.53,0,0,1,301.14,284a8.49,8.49,0,0,1-13.87.16h0A8.42,8.42,0,0,1,287.07,274.51Z"
          transform="translate(-66.11 -121.87)"/>
      <path
          class="low"
          @click="onSelect('GAD7')"
          :fill="GAD7.value >= 1?  GAD7.color : '#ededed'"
          d="M303.14,294.37a52.66,52.66,0,0,0,13.5-41.3,8.52,8.52,0,0,1,5.81-9.07h0a8.51,8.51,0,0,1,11.08,7.13,69.61,69.61,0,0,1-17.91,54.78,8.5,8.5,0,0,1-13.13-.77h0A8.52,8.52,0,0,1,303.14,294.37Z"
          transform="translate(-66.11 -121.87)"/>
      <path
          class="moderate"
          @click="onSelect('GAD7')"
          :fill="GAD7.value >= 2?  GAD7.color : '#ededed'"
          d="M318.3,314.63a77.62,77.62,0,0,0,22.52-69,8.46,8.46,0,0,1,5.76-9.44h0a8.52,8.52,0,0,1,11,6.72,94.55,94.55,0,0,1-27.35,83.84,8.53,8.53,0,0,1-12.86-1.05h0A8.45,8.45,0,0,1,318.3,314.63Z"
          transform="translate(-66.11 -121.87)"/>
      <path
          class="high"
          @click="onSelect('GAD7')"
          :fill="GAD7.value >= 3?  GAD7.color : '#ededed'"
          d="M333.43,334.72a102.57,102.57,0,0,0,31.45-96.47,8.53,8.53,0,0,1,5.67-9.88h0a8.5,8.5,0,0,1,10.95,6.35,119.41,119.41,0,0,1-36.7,112.68,8.5,8.5,0,0,1-12.58-1.32l0,0A8.54,8.54,0,0,1,333.43,334.72Z"
          transform="translate(-66.11 -121.87)"/>
    </g>

    <g class="STRESS" v-if="STRESS">
      <text v-if="showLabels" class="label" @click="onSelect('STRESS')" :fill="STRESS.color" transform="translate(25 191.82)">{{ STRESS.assessmentLabel }}</text>
      <text v-if="showLabels" class="value" transform="translate(25 213.08)">{{ STRESS.label }}</text>

      <path
          class="very-low"
          @click="onSelect('STRESS')"
          :fill="STRESS.value >= 0?  STRESS.color : '#ededed'"
          d="M237.36,258.71a27.6,27.6,0,0,0,4.78,14.86,8.39,8.39,0,0,1-.23,9.64h0A8.49,8.49,0,0,1,228,283a44.69,44.69,0,0,1-7.64-23.76A8.5,8.5,0,0,1,231.5,251h0A8.42,8.42,0,0,1,237.36,258.71Z"
          transform="translate(-66.11 -121.87)"/>
      <path
          class="low"
          @click="onSelect('STRESS')"
          :fill="STRESS.value >= 1?  STRESS.color : '#ededed'"
          d="M212.68,252.09A52.65,52.65,0,0,0,226,293.43a8.53,8.53,0,0,1,.64,10.76h0a8.5,8.5,0,0,1-13.15.75A69.6,69.6,0,0,1,195.8,250.1,8.5,8.5,0,0,1,206.88,243h0A8.51,8.51,0,0,1,212.68,252.09Z"
          transform="translate(-66.11 -121.87)"/>
      <path
          class="moderate"
          @click="onSelect('STRESS')"
          :fill="STRESS.value >= 2?  STRESS.color : '#ededed'"
          d="M188.51,244.61a77.57,77.57,0,0,0,22.35,69.08,8.45,8.45,0,0,1,.89,11h0a8.52,8.52,0,0,1-12.87,1,94.54,94.54,0,0,1-27.15-83.91,8.52,8.52,0,0,1,11-6.7h0A8.45,8.45,0,0,1,188.51,244.61Z"
          transform="translate(-66.11 -121.87)"/>
      <path
          class="high"
          @click="onSelect('STRESS')"
          :fill="STRESS.value >= 3?  STRESS.color : '#ededed'"
          d="M164.46,237.26a102.53,102.53,0,0,0,31.26,96.52,8.54,8.54,0,0,1,1.22,11.33h0a8.5,8.5,0,0,1-12.59,1.3A119.41,119.41,0,0,1,147.8,233.68a8.51,8.51,0,0,1,10.95-6.33h0A8.54,8.54,0,0,1,164.46,237.26Z"
          transform="translate(-66.11 -121.87)"/>
    </g>

    <g class="CD-RISC2" v-if="$data['CD-RISC2']">
      <text v-if="showLabels" class="label" @click="onSelect('CD-RISC2')" :fill="$data['CD-RISC2'].color" transform="translate(42.85 15.42)">{{ $data['CD-RISC2'].assessmentLabel }}</text>
      <text v-if="showLabels" class="value" transform="translate(42.36 37.52)">{{ $data['CD-RISC2'].label }}</text>

      <path
          class="very-low"
          @click="onSelect('CD-RISC2')"
          :fill="$data['CD-RISC2'].value >= 0?  $data['CD-RISC2'].color : '#ededed'"
          d="M229.28,231.83a44.64,44.64,0,0,1,20.15-14.72,8.49,8.49,0,0,1,11.35,8h0a8.41,8.41,0,0,1-5.47,7.94,27.69,27.69,0,0,0-12.61,9.22,8.44,8.44,0,0,1-9.28,2.8h0A8.5,8.5,0,0,1,229.28,231.83Z"
          transform="translate(-66.11 -121.87)"/>

      <path
          class="low"
          @click="onSelect('CD-RISC2')"
          :fill="$data['CD-RISC2'].value >= 1?  $data['CD-RISC2'].color : '#ededed'"
          d="M204,224.82a69.62,69.62,0,0,1,46.57-34,8.51,8.51,0,0,1,10.2,8.33h0a8.54,8.54,0,0,1-6.83,8.33,52.62,52.62,0,0,0-35.11,25.6,8.53,8.53,0,0,1-10,4h0A8.51,8.51,0,0,1,204,224.82Z"
          transform="translate(-66.11 -121.87)"/>

      <path
          class="moderate"
          @click="onSelect('CD-RISC2')"
          :fill="$data['CD-RISC2'].value >= 2?  $data['CD-RISC2'].color : '#ededed'"
          d="M179.69,217.33A94.59,94.59,0,0,1,251,165.41a8.52,8.52,0,0,1,9.8,8.4h0a8.46,8.46,0,0,1-7.2,8.4A77.57,77.57,0,0,0,194.9,225a8.46,8.46,0,0,1-10.22,4.27h0A8.52,8.52,0,0,1,179.69,217.33Z"
          transform="translate(-66.11 -121.87)"/>

      <path
          class="high"
          @click="onSelect('CD-RISC2')"
          :fill="$data['CD-RISC2'].value >= 3?  $data['CD-RISC2'].color : '#ededed'"
          d="M155.54,209.88a119.39,119.39,0,0,1,95.82-69.72,8.5,8.5,0,0,1,9.42,8.45h0a8.54,8.54,0,0,1-7.64,8.45,102.54,102.54,0,0,0-82,59.71,8.54,8.54,0,0,1-10.41,4.67h0A8.5,8.5,0,0,1,155.54,209.88Z"
          transform="translate(-66.11 -121.87)"/>
    </g>

    <circle v-if="showAnimation" class="innerCircle" cx="199px" cy="137px" r="100%" fill="#fafafa"/>
  </svg>
</template>

<script>
export default {
  name: 'checkup-results-radarchart',
  props: {
    values: Object,
    canvasWidth: {
      type: String,
      default: '60%',
    },
    showDrilldown: {
      type: Boolean,
      default: false,
    },
    showLabels: {
      type: Boolean,
      default: true,
    },
    showAnimation: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ENERGY: null,
      GAD7: null,
      PHQ9: null,
      STRESS: null,
      'CD-RISC2': null,
    };
  },
  watch: {
    values: {
      immediate: true,
      deep: true,
      handler(newValues) {
        this.updateValues(newValues);
      },
    },
    showDrilldown: {
      handler(newValue) {
        this.updateShowDrilldown(newValue);
      },
    },
  },
  mounted() {
    this.updateShowDrilldown(this.showDrilldown);
  },
  methods: {
    updateValues(newValues) {
      [ 'ENERGY', 'STRESS', 'PHQ9', 'GAD7', 'CD-RISC2' ].forEach(assessment => {
        const found = newValues[assessment];
        const corresponding = this.$t('app.assessments.' + assessment);
        const scaleItem = this.$t('app.assessments.simplifiedScoreScale').find(e => e.name === found.simplifiedValue);
        if (found) {
          this[assessment] = {
            assessmentLabel: corresponding.label,
            color: corresponding.color,
            ...scaleItem,
          };
        } else {
          this[assessment] = null;
        }
      });
    },
    updateShowDrilldown(newValue) {
      if (this.$refs.graph) {
        this.$refs.graph.querySelectorAll('.label, .very-low, .low, .moderate, .high').forEach(el => {
          el.style.cursor = newValue ? 'pointer' : 'inherit';
        });
      }
    },
    onSelect(assessment) {
      this.$emit('selected-assessment', assessment);
    },
  },
};
</script>

<style scoped>

@keyframes buttonTransition {
  from {
    r: 100%;
  }

  to {
    r: 0%;
  }
}

.innerCircle {
  animation-name: buttonTransition;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.label {
  font-weight: 600;
}

.value {
  font-size: 12px;
}

svg, svg * {
  user-select: none;
  cursor: inherit;
}
</style>
